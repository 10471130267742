<template>
  <router-layout>
    <div class="page noscroll">
      <div class="head" ref="head">
        <van-icon name="arrow-left" @click="Back" />
        <van-search placeholder="请输入搜索内容" readonly @click="toSearch" />
      </div>
      <div class="pageMain">
        <div class="dropmenu">
          <van-dropdown-menu z-index="100">
            <van-dropdown-item
              :title="typeTitle"
              v-model="type"
              :options="typeOptions"
              @open="menuOpen1"
              @closed="menuClose1"
              @change="typeChange"
            />
            <van-dropdown-item
              :title="areaTitle"
              v-model="houseArea"
              :options="area"
              @open="menuOpen2"
              @closed="menuClose2"
              @change="areaChange"
            />
          </van-dropdown-menu>
        </div>
        <div class="listbd">
          <van-loading
            color="#1989fa"
            type="spinner"
            class="dataLoading"
            v-if="$store.state.dataLoading"
          />
          <div v-else>
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              offset="10"
              v-show="hasData"
            >
              <ul v-show="hasData">
                <li v-for="(item,index) in List" :key="index" @click="toDetail(item)">
                  <div class="listL" :style="{ 'background': 'url(' + item.image + ')'}"></div>
                  <div class="listR">
                    <div>
                      <h2>{{item.title}}</h2>
                      <h3 v-html="item.description"></h3>
                    </div>
                    <div class="btdiv">
                      <span class="area">{{item.create_time}}</span><span class="area">阅读 {{item.read}}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </van-list>
            <van-empty description="暂无内容" v-show="!hasData" />
          </div>
        </div>
      </div>
    </div>
  </router-layout>
</template>
<script>
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      infoType: "",
      loading: false,
      finished: false,
      page: 0,
      hasData: true, //判断有无数据
      home: true,
      drop1: false,
      drop2: false,
      searchvalue: "",
      areaTitle: "区域",
      houseType: "",
      houseArea: "",
      houseDecoration: "",
      area: [{ text: "不限", value: "0" }],
      areaSelect: [],
      typeTitle: "分类",
      type: "",
      typeOptions: [{ text: "不限", value: "0" }],
      type_id: "",
      List: [], //所有数据列表
      areaList: [], //面积范围筛选数据
      area_id: ""
    };
  },
  mixins: [authMixin],
  created() {
    this.$store.commit("setDataLoading", true);
    this.getPlate();
    this.getArea();
    this.getAllData();
    this.infoType = this.$route.query.infoType;
  },
  methods: {
    Back() {
      this.$router.back();
    },
    areaChange(val) {
      //区域选择
      this.areaTitle = "";
      this.area_id = val;
      this.page = 0;
      this.List = [];
      this.finished = false;
      this.$store.commit("setDataLoading", true);
      this.getAllData();
    },
    typeChange(val) {
      // 分类
      this.typeTitle = "";
      this.type_id = val;
      this.page = 0;
      this.List = [];
      this.finished = false;
      this.$store.commit("setDataLoading", true);
      this.getAllData();
    },
    toSearch() {
      this.$router.push({
        path: "/houseSearch",
        query: {
          infoType: this.infoType
        }
      });
    },
    toHome() {
      this.$router.push("/Home");
    },
    menuOpen1() {
      this.drop1 = true;
      if (this.drop1 || this.drop2) {
        this.home = false;
      }
    },
    menuClose1() {
      this.drop1 = false;
      if (!this.drop1 && !this.drop2) {
        this.home = true;
      }
    },
    menuOpen2() {
      this.drop2 = true;
      if (this.drop1 || this.drop2) {
        this.home = false;
      }
    },
    menuClose2() {
      this.drop2 = false;
      if (!this.drop1 && !this.drop2) {
        this.home = true;
      }
    },
    toDetail(item) {
      this.$router.push({
        path: "/HouseDetail",
        query: {
          id: item.id,
          infoType: this.infoType
        }
      });
    },
    getPlate() {
      //获取类别
      this.jobList = [];
      this.$request({
        method: "get",
        url: "/cms/plate",
        params: {
          type: "1"
        }
      }).then(res => {
        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let objdata = `{
                "value": "${item.id}",
                "text": "${item.title}"
               }`;
            this.typeOptions.push(JSON.parse(objdata));
          });
        }
      });
    },
    getArea() {
      this.$request({
        method: "get",
        url: "/cms/area/"
      }).then(res => {
        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let objdata = `{
                "value": "${item.id}",
                "text": "${item.title}"
               }`;
            this.area.push(JSON.parse(objdata));
          });
        }
      });
    },
    getAllData() {
      console.log(this.page);
      this.$request({
        method: "get",
        url: "/cms/house/",
        params: {
          page: this.page,
          area_id: this.area_id,
          tid: this.type_id
        }
      }).then(res => {
        this.$store.commit("setDataLoading", false);
        console.log(res);
        if (res.data && res.data.length) {
          this.hasData = true;
          res.data.forEach(item => {
            if (item.imgs) {
              //有图片
              if (item.imgs.includes(",")) {
                //两张图片
                let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${item.imgs.split(",")[0]}",
                "create_time": "${item.create_time.split(" ")[0]}",
                "description": "${item.description}",
                "read": "${item.num}"
               }`;
                this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
              } else {
                let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${item.imgs}",
                "create_time": "${item.create_time.split(" ")[0]}",
                "description": "${item.description}",
                "read": "${item.num}"
               }`;
                this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
              }
            } else {
              //没有图片
              let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${require("@/assets/images/noimg.jpg")}",
                "create_time": "${item.create_time.split(" ")[0]}",
                "description": "${item.description}",
                "read": "${item.num}"
               }`;
              this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
            }
            this.loading = false;
            this.page = this.List[this.List.length - 1].id;
          });
        } else if (!res.data || (!res.data.length && this.page == 0)) {
          //暂无内容
          this.hasData = false;
        } else if (!res.data || res.data.length == 0) {
          this.finished = true;
        }
      });
    },
    onLoad() {
      //上啦加载更多
      this.getAllData();
    }
  }
};
</script>

<style scoped>
.head {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 100%;
  height: 1.43rem;
  background: #fff;
}
.head i {
  height: 1.4rem;
  font-size: 0.6rem;
  line-height: 1.4rem;
  float: left;
  margin-left: 0.15rem;
  color: #777;
}
.back {
  margin-left: 0.3rem;
  font-size: 0.55rem;
  color: #999;
  text-align: left;
}
.van-search {
  flex: 1;
  padding: 0.26667rem 0.3rem;
}
.swiper img {
  width: 100%;
  border-radius: 0.15rem;
}
.swiper {
  padding-top: 1.45rem;
  border-radius: 0.15rem;
  margin-top: 0;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.dropmenu {
  position: sticky;
  top: 1.2rem;
  z-index: 4;
  height: 1rem;
  background: #fff;
}
.dropmenu .van-dropdown-menu {
  border-bottom: 0.02667rem solid #ebedf0;
}
.dropmenu .van-dropdown-menu__bar {
  box-shadow: none !important;
}
.listbd {
  margin-top: 1.42rem;
  position: relative;
  padding: 0 0.3rem 0.3rem 0.3rem;
}
.listbd li {
  padding: 0.3rem 0;
  border-bottom: 0.02667rem solid #ebedf0;
}
.listbd .listL {
  height: 2.1rem;
    width: 3rem;
    float: left;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}
.listbd .listR {
  height: 2.1rem;
    margin-left: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.listbd .listR h2 {
  font-size: 0.4rem;
  font-weight: normal;
  margin-bottom: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.listbd .listR h3 {
  color: #999;
  font-size: 0.35rem;
  font-weight: normal;
  margin-bottom: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.btdiv .area {
  font-size: 0.32rem;
  color: #777;
  margin-right: .3rem;
}
.btdiv .or {
  color: #f03535;
  font-size: 0.4rem;
  margin-right: 0.22rem;
}
.tohome {
  position: fixed;
  right: 0.1rem;
  bottom: 1rem;
  width: 1.2rem;
  height: 1.2rem;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0.05rem 0.1rem 0.3rem rgba(0, 0, 0, 0.07);
  background-image: url(~@/assets/images/home.svg);
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 0.6rem;
  z-index: 1;
}
.drop_l {
  width: 50%;
  float: left;
  height: 100%;
  border-bottom: 1px solid #eee;
  line-height: 1rem;
  text-align: center;
  font-size: 0.35rem !important;
}
.drop_r {
  width: 50%;
  float: left;
}
</style>
